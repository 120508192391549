<template>
  <div class="h-screen w-screen bg-[#ffffff] fixed top-0 z-[calc(infinity)]">
    <Container is-large>
      <div class="py-3 md:px-8 flex align-center justify-between">
        <h1>
          <NuxtLink to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 408 253"
              fill="none"
              preserveAspectRatio="xMidYMid meet"
              style="width: clamp(40.315px, 100%, 80.63px);height: clamp(25px, 100%, 50px);"
            >
              <g clip-path="url(#clip0_1073_1860)">
                <path
                  d="M34.9776 228.506C36.8488 227.642 38.5041 226.491 39.8716 225.123C41.239 223.756 42.3186 222.101 43.1102 220.229C43.9019 218.358 44.2618 216.199 44.2618 213.896V213.752C44.2618 211.521 43.9019 209.362 43.1102 207.491C42.3186 205.619 41.239 203.892 39.7996 202.525C38.0004 200.725 35.7693 199.358 33.0344 198.35C30.3715 197.343 27.2768 196.911 23.8942 196.911H0V252.328H8.06068V231.601H21.807L37.2806 252.328H47.2126L30.5874 230.161C32.1708 229.801 33.6102 229.298 35.0496 228.65L34.9776 228.506ZM32.6745 221.237C31.523 222.101 30.2276 222.748 28.6442 223.252C27.0609 223.756 25.3336 223.972 23.4623 223.972H8.06068V204.396H23.5343C27.6366 204.396 30.8033 205.259 32.9624 206.987C35.1215 208.642 36.1291 210.945 36.1291 213.896V214.04C36.1291 215.623 35.8412 216.991 35.1935 218.214C34.5458 219.438 33.6821 220.445 32.6026 221.309L32.6745 221.237Z"
                  fill="black"
                />
                <path
                  d="M60.3112 228.002H89.4592V220.589H60.3112V204.252H92.9137V196.767H52.2505V252.184H93.2736V244.699H60.3112V228.002Z"
                  fill="black"
                />
                <path
                  d="M138.687 237.574L106.588 196.767H99.4631V252.184H107.38V210.369L140.27 252.184H146.604V196.767H138.687V237.574Z"
                  fill="black"
                />
                <path
                  d="M197.847 204.684C195.256 202.237 192.089 200.294 188.49 198.854C184.892 197.487 180.933 196.767 176.615 196.767H157.039V252.184H176.615C180.862 252.184 184.892 251.465 188.49 250.025C192.089 248.586 195.184 246.643 197.847 244.196C200.437 241.749 202.525 238.726 203.892 235.415C205.331 232.033 206.051 228.362 206.051 224.476V224.332C206.051 220.445 205.331 216.775 203.892 213.464C202.453 210.154 200.437 207.203 197.847 204.684ZM197.775 224.476V224.62C197.775 227.426 197.271 230.089 196.263 232.536C195.256 234.983 193.888 237.07 192.089 238.87C190.29 240.669 188.059 242.036 185.468 243.044C182.877 244.052 179.926 244.555 176.687 244.555H165.172V204.396H176.687C179.926 204.396 182.877 204.9 185.468 205.979C188.059 206.987 190.29 208.426 192.089 210.226C193.888 212.025 195.328 214.184 196.263 216.631C197.271 219.078 197.775 221.741 197.775 224.548V224.476Z"
                  fill="black"
                />
                <path
                  d="M221.165 228.002H250.313V220.589H221.165V204.252H253.696V196.767H213.104V252.184H254.127V244.699H221.165V228.002Z"
                  fill="black"
                />
                <path
                  d="M295.294 228.506C297.166 227.642 298.821 226.491 300.188 225.123C301.556 223.756 302.635 222.101 303.427 220.229C304.219 218.358 304.579 216.199 304.579 213.896V213.752C304.579 211.521 304.219 209.362 303.427 207.491C302.635 205.619 301.556 203.892 300.116 202.525C298.317 200.725 296.086 199.358 293.351 198.35C290.688 197.343 287.594 196.911 284.211 196.911H260.245V252.328H268.306V231.601H282.052L297.526 252.328H307.457L290.832 230.161C292.416 229.801 293.855 229.298 295.294 228.65V228.506ZM292.991 221.237C291.84 222.101 290.544 222.748 288.961 223.252C287.378 223.756 285.65 223.972 283.779 223.972H268.378V204.396H283.851C287.953 204.396 291.12 205.259 293.279 206.987C295.438 208.642 296.446 210.945 296.446 213.896V214.04C296.446 215.623 296.158 216.991 295.51 218.214C294.863 219.438 293.999 220.445 292.919 221.309L292.991 221.237Z"
                  fill="black"
                />
                <path
                  d="M321.708 228.002H350.856V220.517H321.708V204.18H354.238V196.695H313.647V252.112H354.67V244.627H321.708V227.93V228.002ZM352.583 246.786V250.169H315.662V198.854H352.223V202.237H319.692V222.676H348.84V226.059H319.692V246.858H352.655L352.583 246.786Z"
                  fill="black"
                />
                <path
                  d="M391.375 230.017C392.958 229.657 394.398 229.154 395.837 228.506C397.708 227.642 399.363 226.491 400.731 225.123C402.098 223.756 403.178 222.101 403.97 220.229C404.761 218.358 405.121 216.199 405.121 213.896V213.752C405.121 211.521 404.761 209.362 403.97 207.491C403.178 205.619 402.098 203.892 400.659 202.525C398.86 200.725 396.629 199.358 393.894 198.35C391.231 197.343 388.136 196.911 384.753 196.911H360.787V252.328H368.848V231.601H382.594L398.068 252.328H408L391.375 230.161V230.017ZM383.602 229.37H366.833V250.097H362.874V198.782H384.825C387.992 198.782 390.871 199.214 393.246 200.15C395.693 201.013 397.708 202.309 399.22 203.82C400.443 205.044 401.379 206.483 402.026 208.138C402.674 209.794 403.034 211.665 403.034 213.608V213.752C403.034 215.839 402.674 217.71 402.026 219.366C401.379 221.021 400.443 222.388 399.22 223.612C397.996 224.835 396.557 225.843 394.901 226.635C393.246 227.426 391.375 228.002 389.36 228.362L387.704 228.65L403.898 250.169H399.076L383.602 229.441V229.37Z"
                  fill="black"
                />
                <path
                  d="M394.757 222.892C396.125 221.885 397.133 220.589 397.924 219.15C398.716 217.639 399.076 215.983 399.076 214.04V213.896C399.076 210.298 397.708 207.419 395.117 205.332C392.526 203.316 388.928 202.309 384.394 202.309H366.833V225.987H384.25C386.337 225.987 388.28 225.699 390.079 225.195C391.879 224.62 393.462 223.9 394.757 222.82V222.892ZM384.322 223.972H368.92V204.396H384.394C388.496 204.396 391.663 205.26 393.822 206.987C395.981 208.642 396.989 210.945 396.989 213.896V214.04C396.989 215.623 396.701 216.991 396.053 218.214C395.405 219.438 394.542 220.445 393.462 221.309C392.31 222.173 391.015 222.82 389.432 223.324C387.848 223.828 386.121 224.044 384.25 224.044L384.322 223.972Z"
                  fill="black"
                />
                <path
                  d="M0 156.608H37.8564L59.7354 104.285H21.879L0 156.608Z"
                  fill="black"
                />
                <path
                  d="M95.7926 0H65.4211L95.7926 72.6181L130.914 156.608H168.771L103.35 0H95.7926Z"
                  fill="black"
                />
                <path
                  d="M150.778 0L216.271 156.608H254.127L188.706 0H150.778Z"
                  fill="black"
                />
                <path
                  d="M346.106 57.5043L330.704 94.3532L328.833 98.7434L323.651 111.266L304.723 156.608H342.579L379.5 68.2999L408 0H370.144L346.106 57.5043Z"
                  fill="black"
                />
                <path
                  d="M274.063 0H236.135L271.04 85.7887H308.897L274.063 0Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_1073_1860">
                  <rect
                    width="408"
                    height="252.184"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </NuxtLink>
        </h1>

        <div class="flex gap-[0.25rem] align-center">
          <v-icon
            size="32"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </div>
      </div>
    </Container>

    <div class="h-100 w-100 flex flex-col justify-center align-center gap-8 bg-[#fbfbfb]">
      <NuxtLink
        to="/generate"
        target="_blank"
        class="block p-4"
      >
        ログイン
      </NuxtLink>

      <NuxtLink
        to="https://docs.google.com/forms/d/e/1FAIpQLSdSdX54lJ_HZ5k0vWSWXCToCobcGu4QauwNm13uG-LTKySnzQ/viewform"
        class="block p-4"
      >
        いますぐ始める
      </NuxtLink>
    </div>
  </div>
</template>
